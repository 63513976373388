<template>
    <div>
        <v-row justify="center" class="lfont">

            <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="lfont">ລາຍຊື່ຜູ້ສະໝັກ</span>
                    </v-card-title>
                    <v-card-text>
                        <table class="table" v-if="aplication">
                            <tr>
                                <th width="50">#</th>
                                <th>ຊື່ຜູ້ສະໝັກ</th>
                                <th>ທີ່ຢູ່</th>
                                <th>ເບີໂທ</th>
                                <th></th>
                            </tr>
                            <tbody>
                                <tr v-for="(item, index) in aplication" class="text-primary lfont">
                                    <td><v-checkbox class="m-0 p-0" v-model="selecting_applicant"
                                            :value="item.applicant_apply_job.id"></v-checkbox>
                                    </td>
                                    <td>

                                        {{ item.applicant_apply_job.gender == 'male' ? 'ທ້າວ' : 'ນາງ' }} {{
                                            item.applicant_apply_job.name }} {{ item.applicant_apply_job.surname }}
                                    </td>
                                    <td>
                                        ບ້ານ {{ item.applicant_apply_job.village }},
                                        ເມືອງ {{ item.applicant_apply_job.district.name }},
                                        ແຂວງ {{ item.applicant_apply_job.province.name }}
                                    </td>
                                    <td>{{ item.applicant_apply_job.tel }}</td>
                                    <td>
                                        <v-btn class="mx-2" fab dark small color="primary"
                                            @click="show_detail(item.applicant_apply_job)">
                                            <v-icon dark>
                                                mdi-eye
                                            </v-icon>
                                        </v-btn>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <v-btn depressed color="primary" class="text-white lfont" @click="selection_applicant_apply">
                            ບັນທຶກການເລືອກ
                        </v-btn>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="$emit('close')">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <modalShowApplicantDetail :dialog="dialogShowDetail" :data="applicant_detail"
                @close="dialogShowDetail = false" />
        </v-row>
    </div>
</template>
<script>
import modalShowApplicantDetail from '@/components/SelectionApplicant/modals/modalShowApplicantDetail';
export default {
    components: {
        modalShowApplicantDetail
    },
    props: {
        dialog: "",
        aplication: false,
        request_id: "",
    },
    data() {
        return {
            notifications: false,
            sound: true,
            widgets: false,
            selecting_applicant: [20, 21],
            dialogShowDetail: false,
            applicant_detail: []
        }
    },
    watch: {
        // Watch for changes in the application ID
        aplication(newVal, oldVal) {
            if (newVal) {
                // Call the method to fetch data from the new application ID
                this.fetchDataFromApplicationId(newVal);
            }
        }
    },
    methods: {
        fetchDataFromApplicationId(applicationId) {
            // Assuming you have some logic to fetch data from the application ID
            // and it returns an array of selected applicants
            const selectedApplicants = this.aplication
                .filter(item => item.requester_selected === 1)
                .map(item => item.applicant_apply_job.id);

            // Assign the fetched data to selecting_applicant
            this.selecting_applicant = selectedApplicants;
        },
        show_detail(item) {
            this.dialogShowDetail = true;
            this.applicant_detail = item;
        },
        selection_applicant_apply() {
            this.$emit('close');
            this.$emit('isLoading', true);
            this.$axios
                .post(`/employee/requester/choose/applicant/${this.request_id}`, { "applicant_apply_job_id": this.selecting_applicant })
                .then((res) => {
                    if (res.status === 200) {
                        this.$emit('success');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
}
</script>