<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="600px" persistent>
            <v-card class="lfont">
                <v-card-title>
                    <span class="text-h6"><span class="lfont">ລາຍລະອຽດຜູ້ສະໝັກ</span></span>
                </v-card-title>
                <v-card-text v-if="dialog">
                    <a :href="data.profile" target="_blank" v-if="data.profile">
                        <div class="choose-image">
                            <div class="show-image">
                                <i class="fas fa-file-pdf"></i>
                                <p>Resume File(PDF)</p>
                                <embed class="embed-pdf" :src="data.profile" type="application/pdf"
                                    accept="application/pdf" />
                            </div>
                        </div>
                    </a>
                    <p>ຊື່ຜູ້ສະໝັກ: <span class="text-primary">{{ data.gender == 'male' ? 'ທ້າວ' : 'ນາງ' }} {{ data.name }}
                            {{ data.surname }}</span></p>
                    <p>ອາຍຸ: <span class="text-primary">{{ data.age }} ປີ</span></p>
                    <p>ທີ່ຢູ່: <span class="text-primary">ບ້ານ {{ data.village }}, ເມືອງ {{ data.district.name }}, ແຂວງ {{
                        data.province.name }}</span></p>
                    <p>ເບີໂທ: <span class="text-primary">{{ data.tel }}</span></p>
                    <p>Email: <span class="text-primary">{{ data.email }}</span></p>
                    <p>ສະຖານະພາບ: <span class="text-primary">{{ data.married_status }}</span></p>
                    <p>ສັນຊາດ: <span class="text-primary">{{ data.nationality }}</span></p>
                    <p v-if="data.salary > 0">ເງີນເດືອນຕ້ອງການ: <span class="text-primary">{{ data.salary }} {{
                        data.salary_unit }}</span></p>
                    <hr>
                    <p>ການສຶກສາ</p>
                    <table class="table">
                        <tr>
                            <th>ລະດັບການສຶກສາ</th>
                            <th>ສະຖາບັນການສຶກສາ</th>
                            <th>ສາຂາ/ວິຊາຮຽນ</th>
                            <th>ຕັ້ງແຕ່ປີໃດ</th>
                            <th>ຫາປີໃດ</th>
                        </tr>
                        <tbody>
                            <tr v-for="(edu_item, k) in data.education" class="text-primary">
                                <td>{{ edu_item.level }}</td>
                                <td>{{ edu_item.institutions }}</td>
                                <td>{{ edu_item.branch }}</td>
                                <td>{{ edu_item.from_year }}</td>
                                <td>{{ edu_item.to_year }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <p>ປະສົບການເຮັດວຽກ</p>
                    <table class="table">
                        <tr>
                            <th>ບ່ອນປະຈຳການ</th>
                            <th>ຕຳແໜ່ງ</th>
                            <th>ໄລຍະເວລາ</th>
                            <th>ເງີນເດືອນ</th>
                            <th>ເຫດຜົນທີ່ລາອອກ</th>
                        </tr>
                        <tbody>
                            <tr v-for="(exp_item, k) in data.experience" class="text-primary">
                                <td>{{ exp_item.workplace }}</td>
                                <td>{{ exp_item.workplace }}</td>
                                <td>{{ exp_item.position }}</td>
                                <td>{{ exp_item.salary }} {{ exp_item.salary_unit }}</td>
                                <td>{{ exp_item.reason_resiged }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <p>ທັກສະ ແລະ ຄວາມສາມາດ</p>
                    <table class="table">
                        <tr>
                            <th>ຫົວຂໍ້</th>
                            <th>ຄວາມສາມາດ</th>
                        </tr>
                        <tbody>
                            <tr v-for="(skill_item, k) in data.skills" class="text-primary">
                                <td>{{ skill_item.title }}</td>
                                <td>
                                    <v-radio-group v-model="skill_item.ability_level" row class="m-0">
                                        <v-radio :label="level_item.lable" :value="level_item.value"
                                            v-for="level_item in ability_levels" class="text-primary"></v-radio>
                                    </v-radio-group>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="$emit('close')">
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        dialog: "",
        data: false
    },
    data() {
        return {
            ability_levels: [
                {
                    "lable": "ດີຫຼາຍ",
                    "value": "4"
                },
                {
                    "lable": "ດີ",
                    "value": "3"
                },
                {
                    "lable": "ປານກາງ",
                    "value": "2"
                },
                {
                    "lable": "ພໍໃຊ້",
                    "value": "1"
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
selection-controls .v-radio>.v-label {
    color: #0c91f5 !important;
}
</style>